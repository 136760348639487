import React from "react";
import "./App.scss";

import homeBg from "./IMG_0128.jpg";
import imprBg from "./IMG_0132.jpg";
import leistBg from "./IMG_0164.jpg";
import Header from "./Header";

import { Router } from "@reach/router";
import Footer from "./Footer";

function App() {
  const Home = () => (
    <div id="home">
      <Header />
      {/* <div className="modal open">
        <div className="modalContent">
          <button className="close" onClick={(e)=>{
            e.target.parentElement.parentElement.classList.remove("open")
          }}>X</button>
          <h2>Wichtiger Hinweis</h2>
          <p>
          Leider sind wir aufgrund einer Störung der Telekom nicht erreichbar.
            <br />
            Daher bitten wir Sie, uns zwecks Terminvergabe per E-Mail zu
            kontaktieren:
            <br />
            <a href="mailto:anmeldung@dres-schlich.de" className="red btn">
              Jetzt E-Mail schreiben
            </a>
          </p>
        </div>
      </div> */}
      <main>
        <div className="content">
          <h1>Dr. med. Marcus Schlich</h1>
          <span className="h1Sub red">Internist</span>

          <p>
            Vereinbaren Sie jetzt einen
            <br /> Termin:{" "}
            <a href="tel:+496852259" className="red">
              06852 / 259
            </a>
          </p>
        </div>
        <div className="mainImg">
          <div className="imgWrapper">
            <img src={homeBg} className="mainBg" alt="deko bild" />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );

  const Leistungen = () => (
    <div className="leistungen">
      <Header />
      <main>
        <div className="content">
          <h1>Unsere Leistungen</h1>
          <span className="h1Sub red">Das leisten wir für Sie:</span>

          <p>
            <h3>Elektrokardiogramm (EKG)</h3>
            MIttels EKG können Herzrhythmus und Herzfrequenz bestimmt werden und
            die elektrische Aktivität von Herzvorhöfen und Herzkammern beurteilt
            werden. Diese Diagnostik ist zur Beurteilung von
            Herzrhythmusstörungen und akuten Durchblutungsstörungen des Herzens
            entscheidend.{" "}
          </p>

          <p>
            <h3>Belastungs-EKG</h3>
            Zur besseren Detektion von Durchblutungsstörungen des Herzens ist es
            manchmal notwendig, das Herz unter Belastung zu beurteilen. Bei
            dieser Untersuchung wird ein EKG geschrieben, während Sie auf einem
            Ergometer in die Pedale treten.
          </p>

          <p>
            <h3>Langzeit-EKG</h3>
            Nicht jede Herzrhythmusstörung besteht dauerhaft, sodass es
            notwendig sein kann, den Herzrhythmus über einen längeren Zeitraum
            aufzuzeichnen. Hierzu dient das Langzeit-EKG bei dem mittels eines
            kleinen Recorders der Herzrhythmus über 24 Stunden dokumentiert
            wird.
          </p>

          <p>
            <h3>24-Stunden- Blutdruckmessung</h3>
            Die Diagnostik eines Bluthochdrucks bedarf der Beurteilung der
            Blutdruckwerte zu verschiedenen Zeitpunkten des Tages und bei
            verschiedenen Aktivitäten sowie des Blutdruckverhaltens in der
            Nacht. Hierfür dient die Aufzeichnung mittels
            24-Stunden-Blutdruckmessung.
          </p>

          <p>
            <h3>Lungenfunktionsprüfung</h3>
            Die Funktion Ihrer Lunge kann mit Hilfe unserer
            Lungenfunktionsprüfung genau analysiert werden, wodurch bei Bedarf
            eine optimale Therapie eingeleitet und überprüft werden kann.
          </p>

          <p>
            <h3>Schlafapnoescreening</h3>
            Die Untersuchung des Nachtschlafs mit Messung der Atemtätigkeit und
            der Sauerstoffsättigung im Blut zur Detektion von Atemaussetzern und
            mangelnder Sauerstoffversorgung ist ein wichtiger Bestandteil der
            modernen schlafmedizinischen Diagnostik.
          </p>

          <p>
            <h3>Ultraschalluntersuchungen</h3>
            Die Untersuchung der Organe mittels Ultraschall ist ein wesentlicher
            Bestandteil einer modernen internistischen Diagnostik bei
            zahlreichen Krankheitsbildern. In unserer Praxis führen wir die
            sonographische Untersuchung des Bauchraums (Leber, Nieren, Milz,
            Bauchspeicheldrüse etc.), der Schilddrüsen und der Beinvenen durch.
            Des Weiteren können wir als Privatleistung zusätzlich die
            Ultraschalluntersuchung des Herzens und der Halsgefäße anbieten.
          </p>

          <p>
            <h3>Laboruntersuchungen</h3>
            Natürlich gehört auch ein umfassendes Angebot an Blutuntersuchungen
            zu unseren Leistungen. Die Auswahl der untersuchten Blutwerte sollte
            im Vorfeld mit dem Arzt besprochen werden. Die Blutentnahme erfolgt
            nach telefonischer Voranmeldung zwischen 7:30 Uhr und 11:00 Uhr.
          </p>

          <p>
            <h3>Vorsorgeuntersuchungen</h3>
            Check-Up-Untersuchungen einmalig zwischen dem 18. und 35. Lebensjahr
            und alle 3 Jahre ab dem 35. Lebensjahr, Krebsvorsorge für den Mann
            ab dem 45. Lebensjahr, Darmkrebsfrüherkennung für Männer und Frauen
            ab dem 50. Lebensjahr sind von Ihrer Krankenkasse bezahlte
            Vorsorgeleistungen, die regelmäßig durchgeführt werden sollten.
          </p>

          <p>
            <h3>Tauglichkeitsuntersuchungen</h3>
            z. B. für Taucher, Sportvereine etc.
          </p>

          <p>
            <h3>Allgemeine Impfung und Reiseimpfungen</h3>
            Eine regelmäßige Kontrolle des bestehenden Impfschutzes ist ein
            wesentlicher Teil der hausärztlichen Betreuung. Des Weiteren beraten
            wir Sie sehr gerne zur Krankheitsprophylaxe vor geplanten
            Urlaubsreisen und der hierfür nötigen Impfungen.
          </p>

          <p>
            <h3>Manuelle Medizin</h3>
            Die Manuelle Medizin befasst sich mit der Diagnostik und Therapie
            reversibler (heilbaren) Funktionsstörungen der Wirbelsäule und der
            Extremitäten. Hierbei werden mit den Händen Befunde detektiert, die
            oft auch modernen technischen Untersuchungsverfahren entgehen. Im
            Anschluss kann in der Regel mit sanften, risikoarmen
            manualtherapeutischen Mobilisations- und Manipulationstechniken die
            Störung beseitigt werden.
          </p>

          <p>
            <h3>
              Weitere Leistungen, die in unserer Praxis von Dr. Paul-Heinz
              Schlich und Dr. Traudel Schlich angeboten werden:
            </h3>
          </p>

          <p>
            <h3>Flugtauglichkeitsuntersuchungen der Klasse 2</h3>
          </p>

          <p>
            <h3>Feuerwehruntersuchungen inklusive G26</h3>
          </p>

          <p>
            <h3>
              Betriebsärztliche Betreuung und Untersuchungen nach Grundsätzen
            </h3>
          </p>
        </div>
        <div className="mainImg">
          <div className="imgWrapper">
            <img src={leistBg} className="mainBg" alt="deko bild" />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );

  const Impressum = () => (
    <div className="impressum">
      <Header />
      <main>
        <div className="content">
          <h1>Impressum</h1>
          <p>Angaben gemäß § 5 TMG</p>
          <p>
            Dr med. Marcus Schlich <br />
            Buchwaldstr. 47
            <br />
            66625 Nohfelden <br />
          </p>
          <p>
            {" "}
            <strong>Vertreten durch: </strong>
            <br />
            Dr med. Marcus Schlich
            <br />
          </p>
          <p>
            <strong>Kontakt:</strong> <br />
            Telefon: 06852-259
            <br />
            Fax: 06852-1790
            <br />
            E-Mail:{" "}
            <a href="mailto:info@dres-schlich.de">info@dres-schlich.de</a>
          </p>
          <p>
            <strong>Aufsichtsbehörde:</strong>
            <br />
            Kassenärztliche Vereinigung Saarland
            <br />
            Europaallee 7 - 9<br />
            66113 Saarbrücken
            <br />
            Telefon 0681 99 83 70
            <br />
            Fax 0681 99 83 7-140
            <br />
          </p>
          <p>
            <strong>Berufsbezeichnung:</strong>
            <br />
            Ärzte
            <br />
            Zuständige Kammer: Ärztekammer des Saarlandes
            <br />
            Verliehen durch: Bundesrepublik Deutschland
            <br />
            Es gelten folgende berufsrechtliche Regelungen: Arzt-Gesetzbuch
            Regelungen einsehbar unter:{" "}
            <a
              href="https://www.aerztekammer-saarland.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.aerztekammer-saarland.de
            </a>
            .
          </p>
          <br />
          <p>
            <strong>Haftungsausschluss: </strong>
            <br />
            <br />
            <strong>Haftung für Inhalte</strong>
            <br />
            <br />
            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für
            die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können
            wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir
            gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
            allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
            als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
            gespeicherte fremde Informationen zu überwachen oder nach Umständen
            zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
            <br />
            <br />
            <strong>Haftung für Links</strong>
            <br />
            <br />
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
            Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
            nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
            <br />
            <br />
            <strong>Urheberrecht</strong>
            <br />
            <br />
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
            Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
            Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
            gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
            werden wir derartige Inhalte umgehend entfernen.
            <br />
            <br />
            <strong>Datenschutz</strong>
            <br />
            <br />
            Die Nutzung unserer Webseite ist in der Regel ohne Angabe
            personenbezogener Daten möglich. Soweit auf unseren Seiten
            personenbezogene Daten (beispielsweise Name, Anschrift oder
            eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
            auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
            Zustimmung nicht an Dritte weitergegeben. <br />
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich. <br />
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
            Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
            angeforderter Werbung und Informationsmaterialien wird hiermit
            ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich
            ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung
            von Werbeinformationen, etwa durch Spam-Mails, vor.
            <br />
          </p>
          <br />
        </div>
        <div className="mainImg">
          <div className="imgWrapper">
            <img src={imprBg} className="mainBg" alt="deko bild" />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );

  //   const Impfung = () => (
  //     <div className="impressum">

  //       <Header />
  //       <main>
  //         <div className="content">
  //         <h1>Wichtige Hinweise zur Corona-Schutzimpfung:</h1><p><b>Alle
  //           Patienten, die sich gegen Corona impfen lassen wollen, sei es
  //           als
  //           Auffrischungsimpfung oder im Rahmen der Grundimmunisierung,
  //           können gerne einen
  //           Termin in der Praxis vereinbaren.</b></p>
  // <p>Die Terminvergabe ist persönlich in der Praxis, telefonisch über 06852/259 oder per eMail über <a href="mailto:impfung@dres-schlich.de">impfung@dres-schlich.de</a> möglich.</p>
  // <h2>Auffrischungsimpfung:</h2>
  // <p>Die ständige Impfkommission empfiehlt allen Menschen über 18 Jahren eine Auffrischungsimpfung mit einem mRNA-Impfstoff (Moderna oder Biontech) 6 Monate nach der 2. Impfung.</p>
  // <p>Personen, die mit dem Impfstoff von Johnson & Johnson geimpft wurden, wird frühestens 4 Wochen nach der ersten Impfung eine Auffrischungsimpfung mit einem mRNA-Impfstoff (Moderna oder Biontech) empfohlen.</p>

  // <p>Wir freuen uns auf hoffentlich viele schützende Impfungen,<br />Ihr Team der Praxis Dr. Schlich</p>
  // <p><a href="https://www.rki.de/DE/Content/Infekt/Impfen/Materialien/Downloads-COVID-19/Einwilligung-de.pdf?__blob=publicationFile" target="_blank" rel="noopener noreferrer">Download Anamnese für mRNA-Impfstoffe (PDF)</a></p>
  // <p><a href="https://www.rki.de/DE/Content/Infekt/Impfen/Materialien/Downloads-COVID-19/Aufklaerungsbogen-de.pdf?__blob=publicationFile" target="_blank" rel="noopener noreferrer">Download Aufklärungsmerkblatt für mRNA-Impfstoffe (PDF)</a></p>
  //         </div>
  //         <div className="mainImg">
  //           <div className="imgWrapper">
  //           <img src={imprBg} className="mainBg" alt="deko bild"/>
  //           </div>

  //         </div>

  //       </main>
  //       <Footer />

  //     </div>
  //   );

  return (
    <div className="App">
      <Router>
        <Home path="/" />
        <Leistungen path="leistungen" />
        <Impressum path="impressum" />
      </Router>
    </div>
  );
}

export default App;
